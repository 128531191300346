import axios from 'axios';
import { errorApiInstagram, startApiInstagram, successGetAllApi, successGetApiInstagram } from '../reducers/apiInstagramHistory.reducer';

//-------
export const getApiInstagramInfluenceur = async (id,dispatch)=>{
  dispatch(startApiInstagram());
  try{ 
     const res = await axios({
      method:"get",
      url:`${process.env.REACT_APP_URL_SERVER}/api/v1/apiInstagramHistory/${id}`,
      withCredentials:true
    })

  
    dispatch(successGetApiInstagram(res.data))
     
  }catch(err){
    dispatch(errorApiInstagram())
  }
 }

 //----
 
//-------  
export const getAllApiInstgEachInfluenceur = async (dispatch)=>{
  dispatch(startApiInstagram());
  try{ 
     const res = await axios({
      method:"get",
      url:`${process.env.REACT_APP_URL_SERVER}/api/v1/apiInstagramHistory/allApiEachInfluenceur`,
      withCredentials:true
    })

  
    dispatch(successGetAllApi(res.data))
     
  }catch(err){
    dispatch(errorApiInstagram())
  }
 }

