import React, { useEffect, useState } from 'react'
import {Bar, Line} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'
import axios from 'axios';
import dateformat from "dateformat";

export default function Graph({data}) {


  const [apiValue,setApiValue]=useState([]);

  useEffect(() => {
    data?.map(async (row)=>{
         await axios.get(row?.path)
               .then((res)=>{
                 setApiValue((prev)=>[...prev,res.data.data.edge_followed_by.count])
               })
               .catch(err=>console.log(err))
    })
  }, [data]);


  const influenceurApi={
    labels:data?.map((row,index)=>dateformat(row.createdAt,'dd/mm/yyyy')),
    datasets:[{
     label:"variation des nombres abonnes",
     data:apiValue,
     backgroundColor:['#01579b','#D800A6'],
     borderColor:'#D800A6',
     tension:0.3
  }] 
  }

  return (
    <>
     <Line  
     style={{
      backgroundColor:"#FFF",
      padding:"10px",
      boxShadow:"rgb(0 0 0) 3px 4px 18px"
      }} 
     className="rounded" 
     data={influenceurApi} />
    </>
  )
}
