import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { authForgetPassword } from '../../redux/actions/auth.actions';

function ForgetPassword() {
 const dispatch=useDispatch();
 const location = useLocation(); 

 const Querys = new URLSearchParams(location.search);
 const queryEmail = Querys.get("email");
 const {statusForgetPassword}=useSelector((state=>state.auth));

 useEffect(() => {
  authForgetPassword(queryEmail,dispatch);
 }, []);

 console.log(statusForgetPassword)

  return (
   <>
    {
    statusForgetPassword
    ?
    <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center">
      <h1 className="display-1 fw-bold">Check your box mail</h1>   
      <p className="lead">Go to your box mail to change your password</p>
    </div>
  </div>
  :
  <>
  <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center">
      <h1 className="display-1 fw-bold">You have to register first!</h1>   
    </div>
  </div>
  </>
   }
   </>
  
  )
}

export default ForgetPassword