import React from 'react'
import { GiConfirmed } from 'react-icons/gi'

function SuccessChangePassword() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
              <h1 className="display-1 fw-bold">Success Change Password</h1>
              <a href="/login" className="btn btn-success">
               Login
              </a>
              <p className="fs-3">
                <span className="text-success ">
                  <GiConfirmed />
                </span>
              </p>
            </div>
     </div>
    </>
  )
}

export default SuccessChangePassword