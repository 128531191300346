import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { basicSchemaChangePasswordProfil } from "../../schemas";
import { UidContext } from "../../contexts/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";
import NavbarLogin from "../NavBar/NavbarLogin";
import PageDejaConnect from "../PageNotFound/PageDejaConnect";
import isAuth from "../../utils/Auth";
import { useLocation } from "react-router";
import { authChangePassword } from "../../redux/actions/auth.actions";

function ChangePasswordUser() {
  const dispatch=useDispatch();
  const location = useLocation();
  const Querys = new URLSearchParams(location.search);
  const queryToken = Querys.get("token");
  //submit
  const onSubmit = async (values) => {
    authChangePassword(values.newPassword,queryToken,dispatch);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: basicSchemaChangePasswordProfil,
      onSubmit,
    });

 

  return (
    <>
      <NavbarLogin />
      {isAuth().status ? (
        <>
          <PageDejaConnect />
        </>
      ) : (
        <>
          <ToastContainer autoClose={3000} />
          <div className="m-5">
            <div className="container w-50 shadow-lg p-3 mb-5 bg-white roundedd">
              <div className="text-center p-3">Change Password</div>
              <form onSubmit={handleSubmit}>
                <div className="form-outline mb-4">
                  <label className="form-label label-required" htmlFor="newPassword">
                   New Password
                  </label>
                  <input
                    type="password"
                    id="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      "form-control " +
                      (errors.newPassword && touched.newPassword ? "border-danger" : "")
                    }
                  />
                  {errors.newPassword && touched.newPassword && (
                    <p className="text-danger">{errors.newPassword}</p>
                  )}
                  
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label label-required" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      "form-control " +
                      (errors.confirmPassword&& touched.confirmPassword
                        ? "border-danger"
                        : "")
                    }
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <p className="text-danger">{errors.confirmPassword}</p>
                  )}
                  
                </div>

                

                <button  type="submit" className="bleu-btn w-100">
                  submit
                </button>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ChangePasswordUser;
