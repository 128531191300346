import React from "react";
import Footer from "../Footer/Footer";
import NavBarHome from "../NavBar/NavBarHome";
import Redirect from "./Redirect";

function Home() {
  return (
    <>
      <Redirect/>
    </>
  );
}

export default Home;
